@import 'src/utils/utils';

.text {
  @include zero;
  @include Text-16-reg;
  color: $Main-1;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 16px;
  margin-left: 16px;
  
  @media(min-width: $lg) {
    @include box;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}